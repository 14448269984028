
import { Component, Vue, Provide } from "vue-property-decorator";
import SuppliesHeader from "@/components/supplies/SuppliesHeader.vue";
import SuppliesTable from "@/components/supplies/SuppliesTable.vue";
import { mapGetters } from "vuex";
import { BUSINESS } from "@/constants";
import { IPagination } from "@/models/Global";

@Component({
  computed: {
    ...mapGetters("supplies", {
      pagination: "getPagination",
    }),
  },
  components: {
    SuppliesHeader,
    SuppliesTable,
  },
})
export default class Supplies extends Vue {
  @Provide() curr_business = BUSINESS.supplies;

  protected pagination!: IPagination;

  protected get inProgress(): number {
    return this.pagination.openOperations || 0;
  }
}
