
import { Component, Vue, Prop, Watch, Inject } from "vue-property-decorator";
import { mapGetters } from "vuex";
import {
  IPagination,
  ITh,
  IStatusFilter,
  IFilterSettings,
  ISelectOption,
} from "@/models/Global";
import { IUser } from "@/models/User";
import { IPanelConfig, ISuppliesData } from "@/models/Supplies";
import ProfileWidget from "@/components/widgets/ProfileWidget.vue";
import {
  FILTER_STATUS_OPTIONS,
  LIST_TH,
  SUPPLIES_FILTERS_SORT_KEYS,
} from "@/constants/supplies";
import HiboLoader from "@/components/ui/HiboLoader.vue";
import CustomTableWrapper from "@/components/common/CustomTableWrapper.vue";
import HiboMultiSelect from "../ui/HiboMultiSelect.vue";
import HiboLateralPanel from "@/components/ui/HiboLateralPanel.vue";
import SuppliesDocuments from "@/components/supplies/SuppliesDocuments.vue";
import SuppliesTableBody from "@/components/supplies/SuppliesTableBody.vue";
import { IFranchise } from "@/models/Auth";
import { MASTERS_FRANCHISES } from "@/constants";
import SuppliesComments from "@/components/supplies/SuppliesComments.vue";

@Component({
  computed: {
    ...mapGetters("auth", {
      user: "getUser",
      franchises: "getFranchises",
    }),
  },
  components: {
    SuppliesComments,
    ProfileWidget,
    HiboLoader,
    CustomTableWrapper,
    HiboLateralPanel,
    SuppliesDocuments,
    SuppliesTableBody,
  },
})
export default class SuppliesWrapper extends Vue {
  @Inject() readonly curr_business!: string;

  protected user!: IUser;
  protected franchises!: IFranchise[];
  $refs!: {
    multiselect: HiboMultiSelect[] | undefined;
  };

  @Prop({ type: Array, required: true })
  protected operations!: ISuppliesData[];

  @Prop({ type: Boolean, required: true })
  protected loading!: boolean;

  @Prop({ type: Object, required: true })
  protected pagination!: IPagination;

  @Prop({ type: Array, required: true })
  protected statusList!: IStatusFilter[];

  @Prop({ type: String, default: "" })
  protected lostReasonPrefix?: string;

  protected tableThConfig: ITh[] = LIST_TH;
  protected panelConfigs: IPanelConfig = {
    tabs: null,
    lateralPanelStatus: false,
    selectedSupply: null,
    selectedSupplyId: null,
    selectedUserName: null,
  };

  protected get queryParams(): any {
    return this.$route?.query;
  }

  protected get searchId(): number | null {
    return +this.queryParams?.id || null;
  }

  protected getLabel(franchise: IFranchise) {
    return franchise.agentId
      ? `${franchise.agentId} - ${franchise.firstName}`
      : `${franchise.firstName} ${franchise.lastName}`;
  }

  protected get filterFranchisesOptions(): ISelectOption[] {
    const options: ISelectOption[] = [
      { label: "all", name: "all", value: null },
    ];
    if (this.user.agentId)
      options.push({
        label: "myLeads",
        name: "myLeads",
        value: this.user.agentId,
      });
    this.franchises?.forEach((franchise: IFranchise) =>
      options.push({
        label: this.getLabel(franchise),
        name: "franchise_" + franchise.housfyAccountId,
        value: franchise.housfyAccountId,
      })
    );

    return options;
  }

  protected filtersValues: (number[] | string[] | number | string | null)[] = [
    null,
    this.searchId || null,
    this.queryParams?.status || null,
  ];

  protected get filters(): IFilterSettings[] {
    return [
      {
        name: SUPPLIES_FILTERS_SORT_KEYS.FRANCHISE.filter,
        labelKey:
          this.user.id && MASTERS_FRANCHISES.includes(this.user.id)
            ? "franchise"
            : "salesman",
        options: this.filterFranchisesOptions,
        value: this.filtersValues[0],
        availableLevel: this.user.isMaster ? 1 : 0,
        hierarchy: 0,
        filterable: true,
        multiSelect: false,
      },
      {
        name: SUPPLIES_FILTERS_SORT_KEYS.LEAD_ID.filter,
        labelKey: "id",
        options: [],
        value: this.filtersValues[1],
        availableLevel: 0,
        hierarchy: 0,
        filterable: false,
        multiSelect: false,
      },
      {
        name: SUPPLIES_FILTERS_SORT_KEYS.STATUS.filter,
        labelKey: "status",
        options: FILTER_STATUS_OPTIONS,
        value: this.filtersValues[2],
        availableLevel: 1,
        hierarchy: 0,
        filterable: false,
        multiSelect: true,
      },
    ];
  }

  protected paginateOperations(dir: string) {
    let page = this.pagination.page || 1;
    if (dir === "next" && page < (this.pagination.totalPages || 0)) page++;
    else if (dir === "prev" && page > 1) page--;
    this.filterOperations({ page: page });
  }

  protected sortOperations(thId: string) {
    const sortedItem = this.tableThConfig.find((t) => t.id === thId);
    if (sortedItem)
      sortedItem.sortDirection =
        sortedItem?.sortDirection === "desc" ? "asc" : "desc";
    this.filterOperations({
      page: 1,
      sortField: thId,
      sortDirection: sortedItem?.sortDirection || "desc",
    });
  }

  protected async filterOperations(
    pagination: IPagination | null = { page: 1 }
  ) {
    await this.$store.dispatch(this.curr_business + "/retrieveOperations", {
      pagination: pagination,
      filters: this.filters,
    });
  }

  protected removeFilterById() {
    if (!this.$route.query.id) return;
    const query = Object.assign({}, this.$route.query);
    delete query.id;
    this.$router.push({ query });
    this.setFilter(SUPPLIES_FILTERS_SORT_KEYS.LEAD_ID.filter, 0);
  }

  protected setFilter(filterName: string, value: number | string | null) {
    const index = this.filters.findIndex(
      (f: IFilterSettings) => f.name === filterName
    );

    Vue.set(this.filtersValues, index, value);
    this.filterOperations();
    this.retrieveFiltersOptions(index);
  }

  protected async retrieveFiltersOptions(index: number | null = null) {
    if (index !== null)
      this.filters.forEach((f: IFilterSettings, i: number) => {
        if (
          f.hierarchy &&
          (f.hierarchy > this.filters[index].hierarchy ||
            (f.hierarchy === this.filters[index].hierarchy && index !== i))
        )
          Vue.set(this.filtersValues, i, null);
      });
  }

  protected resetFilters() {
    for (let i = 0; i <= this.filtersValues.length - 1; i++)
      Vue.set(this.filtersValues, i, null);
  }

  protected isStatusFilter(filter: string): boolean {
    return filter === SUPPLIES_FILTERS_SORT_KEYS.STATUS.filter;
  }

  protected isFranchisesFilter(filter: string): boolean {
    return filter === SUPPLIES_FILTERS_SORT_KEYS.FRANCHISE.filter;
  }

  protected close() {
    this.panelConfigs.lateralPanelStatus = false;
    this.panelConfigs.selectedSupplyId = null;
  }

  mounted() {
    this.filterOperations();
    this.retrieveFiltersOptions();
  }

  @Watch("searchId")
  searchUpdated() {
    this.resetFilters();
    Vue.set(this.filtersValues, 1, this.searchId);
    this.retrieveFiltersOptions(0);
    if (this.searchId) this.filterOperations();
    if (this.$refs.multiselect) {
      this.$refs.multiselect[0].forceReset();
    }
  }
}
