
import { Component, Prop, Vue, Inject } from "vue-property-decorator";
import OperationsHeader from "@/components/common/OperationsHeader.vue";
import { operationsHeaderInfo } from "@/models/Global";

@Component({
  components: { OperationsHeader },
})
export default class SuppliesHeader extends Vue {
  @Inject() readonly curr_business!: string;

  @Prop({ type: Number, required: true })
  protected totalRequests!: number;

  @Prop({ type: Number, required: true })
  protected inProgress!: number;

  protected get info(): operationsHeaderInfo {
    return {
      inProgress: {
        icon: "icons/houseWithArrow",
        labelKey: "inProgress",
        value: this.inProgress,
      },
    };
  }
}
