
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ProfilePictureWidget from "@/components/widgets/ProfilePictureWidget.vue";
import { ISuppliesData, ISuppliesComment } from "@/models/Supplies";

@Component({ components: { ProfilePictureWidget } })
export default class SuppliesComments extends Vue {
  @Prop({ type: Object, required: true })
  protected supply!: ISuppliesData;

  protected supplyComments: ISuppliesComment[] = [];

  protected async getSuppliesComments() {
    this.supplyComments = await this.$store.dispatch(
      "supplies/getSupplyComments",
      this.supply.id
    );
  }

  @Watch("supply", { immediate: true })
  handleSupply() {
    this.getSuppliesComments();
  }
}
