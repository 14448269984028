
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { IStatusFilter, ITab } from "@/models/Global";
import ProfileWidget from "@/components/widgets/ProfileWidget.vue";
import dayjs from "@/plugins/time";
import { OPERATION_STATUS_LIST, SUPPLIES_TYPES } from "@/constants/supplies";
import { ISuppliesConstantStatusLists, ISuppliesData } from "@/models/Supplies";

@Component({
  components: {
    ProfileWidget,
  },
})
export default class SuppliesTableBody extends Vue {
  @Prop({ type: Array, required: true })
  protected operations!: ISuppliesData[];

  @Prop({ type: Array, required: true })
  protected statusList!: IStatusFilter[];

  @Prop({ type: String, required: true })
  protected lostReasonPrefix!: string;

  protected getDate(date: string, dateFormat: string): string {
    const df: string = this.$t(dateFormat || "dateFormat").toString();
    return dayjs(date).format(df);
  }

  protected getStatusConfig(status: string): IStatusFilter {
    const source = this.statusList;
    return (
      source.find(
        (item) =>
          item.id ===
          OPERATION_STATUS_LIST[
            status.toUpperCase() as keyof ISuppliesConstantStatusLists
          ]
      ) || source[0]
    );
  }

  protected tabs: ITab[] = [
    {
      name: "documents",
      label: "documents",
      component: "HiboDocuments",
      selected: false,
      available: true,
    },
    {
      name: "comments",
      label: "messages",
      component: "HiboComments",
      selected: false,
      available: true,
    },
  ];

  protected lateralPanelStatus: string | null = null;

  protected selectedSupply: ISuppliesData | null = null;

  protected selectedSupplyId: number | null = null;

  protected selectedUserName: string | null = null;

  protected setDataAndOpenLateralPanel(supply: ISuppliesData, status: string) {
    this.tabs.map((tab) => {
      tab.selected = status === tab.name;
    });
    this.lateralPanelStatus = status;
    this.selectedSupply = supply;
    this.selectedSupplyId = supply.id;
    this.selectedUserName = supply.fullName;
    this.handleLateralPanel();
  }

  protected getSupplyTypeLabelKey(typeId: number): string {
    return SUPPLIES_TYPES.find((t) => t.id === typeId)?.labelKey || "";
  }

  @Emit("setLateralPanel")
  handleLateralPanel() {
    return {
      tabs: this.tabs,
      lateralPanelStatus: this.lateralPanelStatus,
      selectedSupply: this.selectedSupply,
      selectedSupplyId: this.selectedSupplyId,
      selectedUserName: this.selectedUserName,
    };
  }
}
