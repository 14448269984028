
import { Component, Vue, Prop, Emit, Inject } from "vue-property-decorator";
import { ITab } from "@/models/Global";

@Component
export default class HiboLateralPanel extends Vue {
  @Inject() readonly curr_business!: string;

  @Prop({ type: Array, default: null })
  protected tabs?: ITab[];

  @Prop({ type: Number, default: null })
  protected id?: number;

  @Prop({ type: String, default: null })
  protected userName?: string;

  @Emit("close")
  close() {}

  @Emit("changeTab")
  changeTab() {}
}
