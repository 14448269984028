
import { Component, Vue } from "vue-property-decorator";
import { IPagination, IStatusFilter } from "@/models/Global";
import { ISuppliesData } from "@/models/Supplies";
import { SUPPLIES_STATUS } from "@/constants/supplies";
import { mapGetters } from "vuex";
import SuppliesWrapper from "@/components/supplies/SuppliesWrapper.vue";

@Component({
  computed: {
    ...mapGetters("supplies", {
      operations: "getOperations",
      loading: "suppliesLoading",
      pagination: "getPagination",
    }),
  },
  components: { SuppliesWrapper },
})
export default class SuppliesTable extends Vue {
  protected operations!: ISuppliesData[];
  protected loading!: boolean;
  protected pagination!: IPagination;

  protected statusList: IStatusFilter[] = SUPPLIES_STATUS;
}
