
import { Component, Prop, Vue, Inject } from "vue-property-decorator";
import LeadFormButton from "@/components/common/LeadFormButton.vue";
import LeadFormsWrapper from "@/components/common/LeadFormsWrapper.vue";
import VueI18n from "vue-i18n";

@Component({
  components: { LeadFormButton, LeadFormsWrapper },
})
export default class OperationsHeader extends Vue {
  @Inject() readonly curr_business!: string;

  @Prop({ type: Number, required: true })
  protected totalRequests!: number;

  @Prop({ type: Boolean, default: false })
  protected canCreateLeads?: boolean;

  protected clientModal = false;

  protected get mainTitle(): string | VueI18n.LocaleMessages {
    switch (this.curr_business) {
      case "mortgages":
        return this.$tc("mortgages", 2);
      case "supplies":
        return this.$tc("supply", 2);
      default:
        return this.$t("applications");
    }
  }

  protected get creationLeadButtonLabelKey(): string {
    switch (this.curr_business) {
      case "mortgages":
        return "newMortgage";
      case "supplies":
        return "newSupply";
      default:
        return "newLead";
    }
  }
}
