
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import DocumentsWidget from "@/components/widgets/DocumentsWidget.vue";
import { IDocument } from "@/models/Documents";
import { ISuppliesData } from "@/models/Supplies";

@Component({ components: { DocumentsWidget } })
export default class SuppliesDocuments extends Vue {
  @Prop({ type: Object, required: true })
  protected supply!: ISuppliesData;

  @Prop({ type: String, default: null })
  protected type?: string;

  protected supplyDocuments: IDocument[] = [];

  protected async getSupplyFiles() {
    this.supplyDocuments = await this.$store.dispatch(
      "supplies/getSupplyFiles",
      this.supply.id
    );
  }

  @Watch("supply", { immediate: true })
  handleSupply() {
    this.getSupplyFiles();
  }
}
